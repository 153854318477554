export const ROUTES = {
  HOME: "/",
  OURSTORY: "/our-story",
  PHOTOS: "/photos",
  WEDDINGPARTY: "/wedding-party",
  QA: "/qa",
  TRAVELANDLODGING: "/travelandlodging",
  REGISTRY: "/registry",
  RSVP: "/rsvp",
  FEEDBACK: "/feedback",
};
