export const uniqueCodes = [
  "ASOLLQ",
  "XZM0VL",
  "AKOOFT",
  "HR122Q",
  "0DGJA1",
  "H1WRDQ",
  "4JAZ0X",
  "JA1LMR",
  "KBTGVO",
  "RTEWD6",
  "L78EJK",
  "TMOFHK",
  "T3JW1G",
  "0Q2XZJ",
  "HZEY9X",
  "T52TRI",
  "H0YIRV",
  "4KNWIS",
  "JBTNZW",
  "IOR9C3",
  "QFSDVY",
  "V8CPK4",
  "S944NF",
  "3XYQ0S",
  "FBV54L",
  "IDFAAB",
  "6RN3FJ",
  "HFYWG1",
  "UVCJ7O",
  "5EHLRL",
  "XTH2N0",
  "RKGDCJ",
  "O6EGF2",
  "ULSGES",
  "E2XMRZ",
  "0ZJX6C",
  "TPY4GA",
  "1ITAF3",
  "EC6T5D",
  "O54D6M",
  "NKYLJ6",
  "PEJ41X",
  "CN5G9L",
  "KYV5XP",
  "6QWZCG",
  "7U64M9",
  "04BG9E",
  "UTMQNC",
  "8ZJFEA",
  "F59RLK",
  "54ZDXH",
  "UPYZA3",
  "50PQT8",
  "DV4ZP7",
  "FWH64Z",
  "4K02MY",
  "JL0XDM",
  "C4QYTD",
  "4ECZ19",
  "M4RILY",
  "YVZ6FR",
  "YP9Q34",
  "3Q9FHI",
  "XLYD5B",
  "NT0JPG",
  "1F85XE",
  "2G3X8C",
  "4KY8AV",
  "Z2UQFP",
  "L9XRHY",
  "3U5W8V",
  "IC7Z1P",
  "OKF3V0",
  "Z6XWL0",
  "O8IN5Y",
  "2X5HVG",
  "WJIEK9",
  "NX19A8",
  "G20YUZ",
  "3P15RK",
  "PXFQVD",
  "YS8ZVC",
  "Y86RT0",
  "4X10YF",
  "W19E4Z",
  "QGDNR6",
  "GF5VYE",
  "48DCJY",
  "ONVZRX",
  "YJK9DH",
  "MZ9S7E",
  "D9R0ZC",
  "LH54W1",
  "RY8I0U",
  "CZ0WKL",
  "6TDFK0",
  "VX5JO1",
  "2NT3GA",
  "G6CJYX",
  "Z1D3R9",
  "Q2BW63",
  "A3XGKP",
  "51KUJ9",
  "YMR41L",
  "69F2BZ",
  "WT7OJ6",
  "FZ2X0R",
  "UZ3TJW",
  "N0XB65",
  "8S4JVF",
  "3HVLO5",
  "7K9W8Y",
  "1Z6PKY",
  "1O9EU4",
  "VH3J9E",
  "Y94C7T",
  "BC7X1F",
  "F1A6H0",
  "J05P4V",
  "O0FW9R",
  "G8YL4D",
  "D47K25",
  "2KXF6W",
  "ZI0TJF",
  "73O60P",
  "5DI2BQ",
  "I7K5D8",
  "P61VRZ",
  "18A7WX",
  "4DWKJE",
  "QOLMXF",
  "2WM7PU",
  "HSL36T",
  "BQSGCY",
  "9AP339",
  "E5P20C",
  "1U40OV",
  "ID445Y",
  "EE3W1T",
  "JQSYJJ",
  "KUPCGL",
  "HN2KVX",
  "FT988H",
  "VH3ELY",
  "60RL1Z",
  "GCFPL0",
  "N1SEBG",
  "K3OXI4",
  "XMOPHT",
  "LPPYJ5",
  "EF9Q18",
  "TEZ2KG",
];
